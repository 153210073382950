import Layout from "../HOC/Layout";
import "../../styles/jobListing/jobListing.scss";
import ListingCard from "./ListingCard";
import { useEffect, useState } from "react";
import ApplyForm from "./ApplyForm";
import Success from "../common/Success";
import ApplyOtp from "./ApplyOtp";
import { getAllProjects } from "../../api/api";
import Spinner from "../common/Spinner";

const JobListing = () => {
    const [openPopup, setOpenPopup] = useState<string>('')
    // const [otp, setOtp] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [jobId, setJobId] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isContent, setIsContent] = useState<boolean>(false);
    const [projectListing, setProjectListing] = useState<Array<any>>([])

    setTimeout(() => setIsContent(false), 4000);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const resp: any = await getAllProjects();
            if (resp && resp.success) {
                setProjectListing(resp.data)
                setIsLoading(false)
            }
        })()
    }, [])
    
    const getPopup = () => {
        if (openPopup === 'form') {
            return <ApplyForm setOpenPopup={(value: string) => setOpenPopup(value)} setEmail={(email: string) => setEmail(email)} jobId ={jobId} />
        } else if (openPopup === 'otp') {
            return <ApplyOtp handleOnPopup={() => setOpenPopup('')} email={email} jobId ={jobId} setOpenPopup={(value: string) => setOpenPopup(value)}  setIsContent ={(value: boolean) => setIsContent(value)} />
        } else if (openPopup === 'success') {
            if(isContent) {
                return  <Success message="Thank you! Your response has been submitted." /> 
            }
        } else {
            return <></>
        }
    }

    return (
        <Layout>
            {getPopup()}
            <div className="jobListing_container">
            {isLoading &&  <Spinner /> }
                {(!isLoading || !!(projectListing || []).length) && <h2 className='heading'>Projects you might like</h2>}
                {
                    (projectListing || []).map((value) => {
                       return  <ListingCard data={value} setApply={(id: string) => { setJobId(id); setOpenPopup('form') }} />
                    })
                }
            </div>
        </Layout>
    )
}

export default JobListing;