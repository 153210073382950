import Main from "./main/Main";
import Register from "./howitwork/Register";
import "../styles/main/common.scss";
import "../styles/Common/font-styles.scss";
import "../styles/Common/font-weight.scss";
import ParticipantTeams from "./team/ParticipantTeams";
// import Awards from "./awards/Awards";
import WinnerTeam from "./participants/winner"
import About from './about/About';
import Navbar from './Navbar';
import Footer from './Footer';

const Home = () => {
  return (
    <>
      <div className='backgroundImage'>
        <Navbar />
        <Main />
      </div>
      <Register />
      <About/>
      <WinnerTeam />
      <ParticipantTeams />
      <Footer />
    </>
  )
};
export default Home;