import "../../styles/jobListing/listingCard.scss";
const parse = require('html-react-parser');

interface IListCard {
    data:{
        _id: string,
        job_title: string,
        job_type: string,
        company_id: {
            name: string,  
            payment_verified: boolean,
            url: string
        }
        resume_link: string,
        budget: number,
        description: string,
        stacks_required:[string],
        totalApplicants: number,
        createdAt:Date

    },
    setApply: Function,
   
}

const ListingCard = ({ data, setApply }:IListCard) => {
    return (
        <div className="listingCard_Container">
            <div className="header_container">
                <h4 className='header small-font'>{data.job_title}</h4>
                <div className='all_icons_container'>{data.job_type}</div>
            </div>
            <div className='project_type ultra_small-font'>
                <span className='text_container ultra_small-font'>
                    By <a className ="link_container" href= {data.company_id.url}  target="_blank">{data.company_id.name}</a>
                </span>
               { data && data.budget && <span className='text_container'>Budget: ₹{data.budget}</span>}
                <span className='text_container time_container'> 
                    Posted {new Date(data.createdAt).getHours()} hours ago
                </span>
            </div>
            <div className='project_discription ultra_small-font'>{parse(data.description)}</div>
            <div className='tags_container ultra_small-font'>
            {data.stacks_required.map((value: string)=> {
              return <div className='tag'>{value}</div>
            })}
            </div>
            {/* <div className='proposals'>
                <span className="title">Proposals: </span>
                <span className="content">{data.totalApplicants}</span>
            </div> */}
            <div className='bottom_container'>
                <div className="verfied_container">
                    <div className='verified_icon'>
                        <svg  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img"><path d="M13.72 7.03c.45-.56.34-1.39-.24-1.82l-.55-.41c-.34-.25-.53-.66-.51-1.08l.03-.68c.03-.72-.53-1.32-1.25-1.33h-.68c-.42 0-.81-.22-1.05-.57L9.11.57c-.39-.6-1.2-.75-1.79-.33l-.55.4c-.34.24-.79.3-1.18.15L4.95.55c-.67-.25-1.41.11-1.64.79l-.21.65c-.14.4-.46.71-.87.82l-.65.18C.89 3.19.5 3.92.71 4.6l.21.65c.13.41.04.85-.22 1.18l-.42.54c-.45.56-.34 1.39.24 1.81l.55.41c.34.25.53.66.51 1.08l-.03.68c-.03.72.54 1.32 1.25 1.33h.68c.42 0 .81.22 1.05.57l.37.57c.39.6 1.21.75 1.79.33l.55-.4c.34-.25.78-.31 1.18-.16l.64.24c.67.25 1.41-.1 1.64-.79l.21-.65c.13-.4.45-.71.86-.82l.65-.17c.69-.19 1.09-.92.87-1.61l-.21-.65c-.13-.4-.05-.85.22-1.18l.42-.53zM6.06 9.84L3.5 7.27l1.23-1.23 1.33 1.33 3.21-3.21L10.5 5.4 6.06 9.84z"></path></svg>
                    </div>
                   {data && data.company_id && data.company_id.payment_verified && <div className="verified_text ultra_small-font bold">
                        Project verified
                    </div>}
                </div>
                <div className="apply_button extra_small-font" onClick={() => setApply(data._id)}>
                    Apply
                </div>
            </div>
        </div>
    )
}
export default ListingCard;
