import { Routes,Route } from "react-router-dom";
import { Helmet } from 'react-helmet'

import "./styles/main/common.scss";
import ContactUs  from "./component/contactUs/ContactUs";
import  Home  from "./component/Home";
import  HowToRegister  from "./component/howToregister/HowToRegister";
import Team from "./component/team/Team";
import ProjectListing from "./component/jobListing/JobListing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contactUs" element={<>
        <Helmet>
          <title>Contact us</title>
          <link href='https://hackuniv.com/contactUs' rel="canonical" />
        </Helmet>
        <ContactUs />
      </>} />
      <Route path="/guide/*" element={<HowToRegister />} />
      <Route path="/teams/:id" element={<Team />} />
      <Route path="/project-listing" element={<ProjectListing />} />
    </Routes>
  );
}

export default App;
