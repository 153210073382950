// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #37474F;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/Common/spinner.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF;AACA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;EACA,uCAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":["@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.loading-spinner {\n  width: 50px;\n  height: 50px;\n  border: 6px solid #f3f3f3;\n  border-top: 6px solid #37474F;\n  border-radius: 50%;\n  animation: spinner 1.5s linear infinite;\n}\n\n.spinner-container {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
