

import "../../styles/register/register.scss";

const Card = (props: any) => {
  const { name, desc} = props.data;
  const field = desc.split('$');
  const step = name.split('$');
  return (
    <div className="register_subhead_layout">
      <div className="register_code small-font">
        <text>{step[0]}</text>
        <text className="bold">{step[1]}</text>
      </div>
      <p className="extra_small-font lighter">
        {field[0]}
        <a target="_blank" href={field[1]}>
          {field[1]}
        </a>
        {field[2]}
      </p>
    </div>
  );
};
export default Card;
