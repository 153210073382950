import { FormEventHandler, MouseEventHandler, ReactComponentElement } from 'react'
import Countdown from 'react-countdown'
import Otp from '../team/Otp'
import Loader from './Loader'
import PopUp from './popup/index'

interface IOtpPopup {
    setOpenPopup: Function,
    handleOnSubmit: FormEventHandler<HTMLFormElement>,
    isTrue: boolean,
    email: string,
    setOtp: Function,
    otp: string,
    error: string,
    handleOnPopup: MouseEventHandler<HTMLButtonElement>,
    resendHandler: MouseEventHandler<HTMLButtonElement>,
    isSubmit: boolean,
    enableResend: boolean,
    resendTimer: number,
    setIsLoading: Function,
    setEnableResend: Function
    children: any
}

interface IRenderer {
    hours?: number,
    minutes: number,
    seconds: number,
    completed: boolean,
}


function OtpPopup({ setOpenPopup, handleOnSubmit, isTrue, setOtp, otp, email, error, handleOnPopup, isSubmit, enableResend, resendHandler, resendTimer, setIsLoading, setEnableResend, children }: IOtpPopup) {
    const renderer = ({ minutes, seconds, completed }: IRenderer) => {
        setIsLoading(true);
        if (completed) {
            // Render a completed state
            setIsLoading(false);
            setEnableResend(true);
            return;
        } else {
            setIsLoading(false);
            // Render a countdown
            return (
                <span>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
            );
        }
    };
    return (
        <PopUp
        width={500}
        height={550}
        handleClose={() => setOpenPopup(false)}
        unsetHeight={true}
        wrapperClassName="padding-20"
      >
        <form onSubmit={handleOnSubmit}>
          {children}
          {isTrue && (
            <>
              <p className="otp_heading">OTP Verification</p>
              <div className="otp_sub_heading">
                Enter OTP we just sent to <span>{email}</span>
              </div>
              <Otp
                setOtp={(value: any) => setOtp(value)}
                otp={otp}
              />
            </>
          )}
          {!!error && (
            <div className="error_container">{error}</div>
          )}
          <div className="button_layout">
            <button
              type="button"
              className="cancel_button"
              onClick={handleOnPopup}
            >
              Cancel
            </button>
            <button type="submit" disabled={isTrue ? !!(otp.length < 4) : !email}>
             
              {isSubmit ? <Loader /> : isTrue ? "Verify" : "Submit"}
            </button>
          </div>
          {isTrue && (
            <>
              <div className="resend_container">
                <div className="resend_content">
                  Didn't receive your code?{" "}
                  <span
                    className={`resendCode ${
                      enableResend ? "" : "disabled"
                    }`}
                    onClick={resendHandler}
                  >
                    Resend code
                  </span>
                </div>
                <Countdown
                  key={Math.random()}
                  date={resendTimer}
                  renderer={renderer}
                />
              </div>
            </>
          )}
        </form>
      </PopUp>
    )
}

export default OtpPopup