import Axios from "axios";
import { SERVER_URL } from './config'


// const SERVER_URL = "https://hackuniv-be.herokuapp.com/api/";

export async function axiosRequest(
  method: string,
  basicUrl: string,
  data = {},
  isData = true
) {
  let URL = `${SERVER_URL}/${basicUrl}`;

  return Axios({
    method: method,
    url: URL,
    headers: {
      "Content-Type": "application/json",
    },
    ...(isData && { data: data }),
    withCredentials: true,
  })
    .then((res) => res)
    .then(res => res.data)
    .catch((err) => {
      if (err?.response?.data) {
        throw err.response.data;
      }
      throw err;
    });
}

export const instruction = [
  {
    route: '/guide/how-to-submit',
    heading: 'How to Submit',
    subHeading: 'Instructions to Follow:-',
    intructions: [
      {
        content: 'Please submit a github repo with code.'
      },
      {
        content: 'Repo should have proper Readme containing steps to clone, prerequisites, how to install dependencies and how to run your code.'
      },
      {
        content: 'If your code is already deployed on a server and you have a live link, please share the same as well.'
      },
      {
        content: 'Also submit a final feature list of your project.'
      }
    ],
    formLink: {
      content: 'Share your Code + Live Link to',
      link: "mailto:anjali.garg@repozitory.com",
      linkName: 'anjali.garg@repozitory.com',
      additionalInfo: 'on or before 30 November 2023.'
    }
  }, {
    route: '/guide/how-to-register',
    heading: 'How to Register',
    subHeading: 'Instructions to Follow:-',
    intructions: [
      {
        content: 'The maximum number of students in a team should be three. '
      },
      {
        content: 'Only one member from a team need to submit the project.'
      }, {
        content: 'For any query you may contact at',
        link: 'anjali.garg@hackuniv.com'
      }
    ],
    formLink: {
      content: 'Fill the Goggle Form carefully and the link is given here:-',
      link: "https://bit.ly/3cjE1TH",
    }
  }
]