// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ultra_small-font {
  font-size: 0.87rem;
}

.extra_small-font {
  font-size: 1rem;
}

.small-font {
  font-size: 1.25rem;
}

.medium-font {
  font-size: 1.5rem;
}

.large-font {
  font-size: 2rem;
}

.extra_large-font {
  font-size: 2.4rem;
}

.ultra_large_font {
  font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/Common/font-styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".ultra_small-font{\n    font-size: 0.87rem;\n}\n\n.extra_small-font{\n    font-size: 1rem;\n}\n\n.small-font{\n    font-size: 1.25rem;\n}\n\n.medium-font{\n    font-size: 1.50rem;\n}\n\n.large-font{\n    font-size: 2rem;\n}\n\n.extra_large-font{\n    font-size: 2.4rem;\n}\n.ultra_large_font{\n    font-size: 3rem;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
