import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";
import "../../styles/register/countdown.scss";

const ShowCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <div className="show_counter extra_large-font bold">
      <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
      <p className="colon">:</p>
      <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
      <p className="colon">:</p>
      <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
      <p className="colon"> :</p>
      <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
    </div>
  );
};

const Expired = () => {
  return (
    <div className="expired medium-font">
      <span>Registration no longer Accepted</span>
    </div>
  );
};

const CountdownTimer = ({ targetDate }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return Expired();
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
}
};

export default CountdownTimer;
