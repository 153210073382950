import "../../styles/register/register.scss"

const info: any = [
  {
    name: "Step 1: $ Project Submission",
    desc:
      "Register by putting your entry into $https://bit.ly/3cjE1TH$ on or before 31 October, 2023. Teams will need to submit the project name, problem, solution and tech stack to be used",
    //  button: 'How to Register',
    //  goto: "/guide/how-to-register"
  },
  {
    name: "Step 2: $ Code + Live Link Submission",
    desc:
      "Project Code and live link of the project needs to be submitted on or before 30 November, 2023. The Code and Link will be evaluated by industry experts and shortlisted teams will be called for final round.",
    button: 'How to submit',
    goto: "/guide/how-to-submit"
  },
  {
    name: "Step 3: $ Final Round",
    desc:
      "Shortlisted teams will be presenting their final project at Guru Jambheshwar University, Hisar. Experts from industry will judge projects and declare winners. Award ceremony will happen on same day.",
  },
];

export default info