// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp_container .form-control {
  transition: none;
  width: 90px;
  height: 100px;
  text-align: center;
  font-size: 40px;
  margin-right: 16px;
  border-radius: 16px;
}
.otp_container .form-control input {
  width: 100% !important;
}
.otp_container .form-control input:focus::placeholder {
  color: transparent;
}
.otp_container .form-control:focus {
  color: #3F4254;
  background-color: #FFFFFF;
  outline: 0;
}
.otp_container .form-control.form-control-solid {
  color: #3F4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.otp_container .form-control.form-control-solid:active,
.otp_container .form-control.form-control-solid.active,
.otp_container .form-control.form-control-solid:focus,
.otp_container .form-control.form-control-solid.focus {
  background-color: #EBEDF3;
  border-color: #EBEDF3;
  color: #3F4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

@media screen and (max-width: 428px) {
  .otp_container .form-control.form-control-solid {
    width: 30%;
  }
}
#otp {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/teams/otp.scss"],"names":[],"mappings":"AACE;EAEE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,sBAAA;AAAN;AAEM;EACE,kBAAA;AAAR;AAKE;EACE,cAAA;EACA,yBAAA;EACA,UAAA;AAHJ;AAME;EAEE,cAAA;EACA,yGAAA;AALJ;AAQE;;;;EAIE,yBAAA;EACA,qBAAA;EACA,cAAA;EACA,yGAAA;AANJ;;AAUA;EAGI;IAEE,UAAA;EAVJ;AACF;AAeA;EACE,aAAA;EACA,uBAAA;AAbF","sourcesContent":[".otp_container {\n  .form-control {\n    -webkit-transition: none;\n    transition: none;\n    width: 90px;\n    height: 100px;\n    text-align: center;\n    font-size: 40px;\n    margin-right: 16px;\n    border-radius: 16px;\n\n    input {\n      width: 100% !important;\n\n      &:focus::placeholder {\n        color: transparent;\n      }\n    }\n  }\n\n  .form-control:focus {\n    color: #3F4254;\n    background-color: #FFFFFF;\n    outline: 0;\n  }\n\n  .form-control.form-control-solid {\n\n    color: #3F4254;\n    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;\n  }\n\n  .form-control.form-control-solid:active,\n  .form-control.form-control-solid.active,\n  .form-control.form-control-solid:focus,\n  .form-control.form-control-solid.focus {\n    background-color: #EBEDF3;\n    border-color: #EBEDF3;\n    color: #3F4254;\n    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;\n  }\n}\n\n@media screen and (max-width:428px) {\n  .otp_container {\n\n    .form-control.form-control-solid {\n\n      width: 30%;\n    }\n\n  }\n}\n\n#otp {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
