// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  height: 180px;
  background: #37474F;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer a {
  display: inline-block;
  margin: 0 10px;
}
.footer a img {
  width: 20px;
  height: 20px;
}
.footer p {
  margin: 0 auto;
  color: #FFFFFF;
  font-style: normal;
  line-height: 35px;
  text-align: center;
}

@media screen and (max-width: 428px) {
  .footer {
    height: 130px;
    width: 100%;
  }
  .footer a img {
    width: 15px;
    height: 15px;
  }
  .footer p {
    font-size: 15px;
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Common/basic.scss","webpack://./src/styles/Common/_colours.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,qBAAA;EACA,cAAA;AAAR;AACQ;EACI,WAAA;EACA,YAAA;AACZ;AAII;EACI,cAAA;EACA,cCMM;EDLN,kBAAA;EACA,iBAAA;EACA,kBAAA;AAFR;;AAKA;EACK;IACG,aAAA;IACA,WAAA;EAFN;EAKU;IACI,WAAA;IACA,YAAA;EAHd;EAOM;IACI,eAAA;IACA,SAAA;EALV;AACF","sourcesContent":["@import '../Common/colours.scss';\n.footer {\n    width: 100%;\n    height: 180px;\n    background: map-get($secondary-color2-shades, shade2);;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    a {\n        display: inline-block;\n        margin: 0 10px;\n        img {\n            width: 20px;\n            height: 20px;\n        }\n    }\n\n\n    p {\n        margin: 0 auto;\n        color: $white-color;\n        font-style: normal;\n        line-height: 35px;\n        text-align: center;\n    }\n}\n@media screen and (max-width:428px) {\n     .footer {\n        height: 130px;\n        width: 100%;\n\n        a {\n            img {\n                width: 15px;\n                height: 15px;\n            }\n        }\n\n        p {\n            font-size: 15px;\n            margin: 0;\n        }\n    }\n}","$primary-color1: #F0AE00;\n$primary-color1-shades: (\n  shade1: #FEDB7E,\n);\n\n$primary-color2: #326CB5;\n$primary-color2-shades: (\n  shade1: #065BBE,\n  shade2: #1F57C3,\n  shade3: #2F91EC,\n  shade4: #0000FF\n);\n\n$primary-color3: #FF0000;\n\n$secondary-color1: #5E6D55;\n\n$secondary-color2: #8E8E8E;\n$secondary-color2-shades: (\n  shade1: #001E00,\n  shade2: #37474F,\n  shade3: #555,\n  shade4: #D5E0D5,\n  shade5: #CCC,\n  shade6: #E4EBE4,\n  shade7: #F5F5F5,\n  shade8: #808080\n);\n\n$white-color: #FFFFFF;\n$black-color: #000000;\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
