import "../styles/Common/basic.scss"
import instaIcon from "../assets/instagram.svg"
import linkedinIcon from "../assets/linkedin.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
       <footer className="footer">
        <p className="small-font">Copyright © {currentYear} hackUniv - All Rights Reserved.</p>
        <div>
          <a href="https://www.instagram.com/hackuniv/">
            <img src={instaIcon} alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/company/hackuniv/">
            <img src={linkedinIcon} alt="Linkedin" />
          </a>
        </div>
      </footer>
      )
}
export default Footer