import Axios from "axios";


const DB_URL="http://45.79.121.211:8000/api/";
export async function fetchRequest(
  method: string,
  basicUrl: string,
  data = {},
  isData = true
){
  let URL = `${DB_URL}${basicUrl}`;

  return Axios({
    method: method,
    url: URL,
    headers: {
      "Content-Type": "application/json",
    },
    ...(isData && { data: data }),
    withCredentials: true,
  })
    .then((res) => res)
    .then(res => res.data)
    .catch((err) => {
      if (err?.response?.data) {
        throw err.response.data;
      }
      throw err;
    });
}