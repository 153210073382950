import { axiosRequest } from "../helper";
import { fetchRequest } from "./helper";

export const contactAPI=async(fieldData: any) =>{
  return axiosRequest("POST", "contact/details", fieldData);
}

export const getCollectionByIdAPI = async (collectionId: string | undefined) => {
  return await axiosRequest("GET", `collection/${collectionId}`);
};

export const teamApi= async(fieldData:any,teamId:any)=>{
return await fetchRequest("GET",`teams/details/${teamId}`,fieldData);
}

export const likeApi=async(fieldData:any,id:any)=>{
return await fetchRequest("POST",`teams/${id}`,{email: fieldData});
}

export const otpApi=async(otp:string,email:string,id:any)=>{
return await fetchRequest("POST",`teams/${id}/verify`,{ otp: otp, email: email,id:id})
}

export const resendApi = async(email:string,id:any)=>{
  return await fetchRequest("POST",`teams/${id}/resend`,{ email: email, id:id})
  }

export const getAllProjects = async () => {
  return await axiosRequest("GET", `project-listing/`)
}


export const createUserApplicant = async(fieldData: any) =>{
  return axiosRequest("POST", "user-applicant/", fieldData);
}

export const verifyUserApplicant = async(fieldData: any) =>{
  return axiosRequest("POST", "user-applicant/verify", fieldData);
}


export const resendUserApplicant = async(fieldData: any) =>{
  return axiosRequest("POST", "user-applicant/resend", fieldData);
}