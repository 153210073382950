import { useState } from "react";

import { createUserApplicant } from "../../api/api";
import "../../styles/contact/contact.scss";
import Input from "../common/Input";
import Loader from "../common/Loader";
import PopUp from "../common/popup/index";
import Textarea from "../common/Textarea";

const userDetails = {
    about_yourself: "",
    name: "",
    phone: "",
    email: "",
    resume_link: "",
    job_id: ""
};

interface ApplyProps {
    setOpenPopup: Function,
    setEmail: Function,
    jobId: string
    
}

const ApplyForm = ({ setOpenPopup, setEmail, jobId }: ApplyProps) => {
    const [contactInfo, setContactInfo] = useState(userDetails);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [error , setError] = useState('')
    const handleChange = (event: any) => {
        contactInfo.job_id = jobId
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: any) => {
        setIsLoader(true)
        try {
            event.preventDefault();
            const resp: any = await createUserApplicant(contactInfo);
            if (resp.success) {
                setEmail(contactInfo.email)
                setIsLoader(false)
                setOpenPopup('otp')
                return;
            }else{
                setIsLoader(false)
                setOpenPopup('otp')
            }
            setContactInfo({ ...userDetails });
        } catch (err: any) {
            setIsLoader(false)
            setError(err.error)
        }
    };

    return (
        <PopUp
            width={600}
            height={500}
            handleClose={() => setOpenPopup('')}
            unsetHeight={true}
            setSizeHeight={true}
        >
            <div className="contact_container">
                <h1 className="main_heading">Applying for Project Name</h1>
                <form onSubmit={handleSubmit} className="form_container">
                <Textarea
                        type="text"
                        label="About Yourself"
                        name="about_yourself"
                        row={3}
                        cols={50}
                        placeholder="Write about yourself"
                        inputClassContainer="input-contact-form"
                        labelContainer="label_container"
                        value={contactInfo.about_yourself}
                        onChange={handleChange}
                    />
                    <Input
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Write your name here"
                        labelContainer="label_container"
                        inputClassContainer="input-contact-form"
                        onChange={handleChange}
                        value={contactInfo.name}
                        required={true}
                    />
                    <Input
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Your mail"
                        labelContainer="label_container"
                        inputClassContainer="input-contact-form"
                        onChange={handleChange}
                        value={contactInfo.email}
                        required={true}
                        // pattern="^[a-zA-Z0-9]+@gmail\.com$"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    />
                    <Input
                        label="Phone"
                        name="phone"
                        type="tel"
                        placeholder="eg. 9999999999"
                        labelContainer="label_container"
                        inputClassContainer="input-contact-form"
                        onChange={handleChange}
                        value={contactInfo.phone}
                        required={true}
                        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        pattern="^[1-9][0-9]{9}$"
                    />
                    <Input
                        label="Resume link"
                        name="resume_link"
                        type="url"
                        placeholder="Enter your resume link here"
                        labelContainer="label_container"
                        inputClassContainer="input-contact-form"
                        onChange={handleChange}
                        value={contactInfo.resume_link}
                        required={true}
                        pattern="https://.*"
                    />
                {error &&  <div className= "form_error_container">{error}</div>}
                    <button type="submit" className="submit_contact_form bottom_5 small-font">
                        {isLoader ? <Loader /> : "Submit"}
                    </button>
                </form>
            </div>
        </PopUp>
    );
};
export default ApplyForm;