import BannerImage from "../../assets/bg_mobile.png";
import "../../styles/main/landingpage.scss";

const Landingpage = () => {
  return (
    <>
      <div className="landing_layout">
        <div className="landing_head">
          <div className="landing_heading">
            <h1 className="main_heading">Hack your way to</h1>
            <h1 className="main_text">Glory!</h1>
          </div>
          <div className="landing_subheading">
            <p className="landing_bottom_heading small-font">
              HackUniv is the event you can't miss! With exciting challenges, networking opportunities and amazing prizes, it's the perfect place to unleash your tech skills and creativity.
            </p>
          </div>
          <img className="mobile_banner_image" src={BannerImage} alt=""></img>
        </div>

      </div>
    </>
  );
};

export default Landingpage;
