import { Link } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import "../styles/main/navbar.scss";
import dot from "../assets/dot.svg"

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = window.location.pathname === "/";

  return (
    <div className="nav_main">
      <div className="logo" onClick={() => navigate("/")}>
        <div className="logo_hackuniv large-font">hackUniv</div>
        <div className="by_repo extra_small-font">
          <span>by</span>
          <span className="repo">repozitory</span>
          <img className="dot" src={dot} alt="dot"/>
        </div>
      </div>
      <div className="navbar small-font">
        <ul className="navbar_container">
          <Link className={`navbar_item ${isHome && "home_navbar"} ${
            location.pathname === '/' ? 'active-tab' : ''
          }`} to="/">
            Home
          </Link>
          <Link
            className={`navbar_item ${isHome && "home_navbar"} ${
              location.pathname === '/contactUs' ? 'active-tab' : ''
            }`}
            to="/contactUs"
          >
            Contact Us
          </Link>
          <Link
            className={`navbar_item ${isHome && "home_navbar"} ${
              location.pathname === '/project-listing' ? 'active-tab' : ''
            }`}
            to="/project-listing"
          >
            Projects
          </Link>
        </ul>
       
      </div>
    </div>
  );
};

export default Navbar;
