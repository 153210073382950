import React from 'react'
import Slider from "react-slick";
import "../../styles/participants/imageCard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = ({ images, imageData }: any) => {

    const settings: any = {
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: true


    };
    return (
        <div className="imgslider">
            <Slider {...settings}>
                {[{ image: imageData }, ...images].map((item: any) => (
                    <div key={item.image}>
                        <img className='image-carousel' src={item.image} alt={item.image} />
                    </div>
                ))}
            </Slider>
        </div>
    )
}
export default ImageSlider;
