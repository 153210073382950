import "../../styles/register/countdown.scss";

const DateTimeDisplay = ({ value, type }:any) => (
  
    <div className="countdown">
      <p>{value}</p>
      <span className="extra_small-font lighter">{type}</span>
    </div>
  
);

export default DateTimeDisplay;