import Footer from '../Footer'
import Navbar from '../Navbar'

function layout ({ children }: any) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default layout;