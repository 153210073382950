// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  transition: opacity 500ms;
  background-color: rgba(25, 24, 22, 0.5);
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.popup_container .popup_subcontainer {
  width: 600px;
  max-width: 80%;
  background-color: white;
  border-radius: 20px;
}
.popup_container .popup_subcontainer .close_image {
  margin: 17px;
  width: 15px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 999;
}
.popup_container .popup_subcontainer .popup_wrapper {
  padding: 45px;
  position: relative;
  color: black;
}

.success_container {
  text-align: center;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/component/common/popup/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,yBAAA;EACA,uCAAA;EACA,mBAAA;EACA,UAAA;EACA,aAAA;AACJ;AAAI;EACI,YAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;AAER;AADQ;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,eAAA;EACA,YAAA;AAGZ;AADQ;EACI,aAAA;EACA,kBAAA;EACA,YAAA;AAGZ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".popup_container {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    transition: opacity 500ms;\n    background-color: rgba(25, 24, 22, 0.5);\n    visibility: visible;\n    opacity: 1;\n    z-index: 9999;\n    .popup_subcontainer {\n        width: 600px;\n        max-width: 80%;\n        background-color: white;\n        border-radius: 20px;\n        .close_image {\n            margin: 17px;\n            width: 15px;\n            position: absolute;\n            right: 8px;\n            top: 8px;\n            cursor: pointer;\n            z-index: 999;\n        }\n        .popup_wrapper {\n            padding: 45px;\n            position: relative;\n            color: black;\n        }\n    }\n}\n\n.success_container{\n    text-align: center;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
