import React, { useEffect, useRef } from "react";

import './index.scss'
import styled from "styled-components";

const Container: any = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  transition: opacity 500ms;
  background-color: rgba(25, 24, 22, 0.5);
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  .padding-20 {
    padding: 20px;
  }
  ${(props: any) =>
    props.setHeight ? "overflow-y:auto;" : "align-items: center;"}
`;

const SubContainer: any = styled.div`

  width: ${(props: any) => props.width && props.width}px;
  max-width: 80%;
  ${(props: any) => 
    props.sizeHeight ? "margin-top: 0rem;" : "margin: 40px auto;"}
  border-radius: 40px;
  background-color: #fff;
  ${(props: any) => (props.setHeight ? "height:fit-content;" : "")}
  @media {
    max-width: 600px;
  }
   {
    max-width: 90%;
  }
`;

const Wrapper: any = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: any) =>
    props.setHeight
      ? ""
      : ` max-height: 90vh;overflow-y: scroll;
    height: ${props.height && props.height}px;`}

  ${(props: any) =>
    props.checkOutPopUp
      ? `
        padding-top: 51px;
        ::-webkit-scrollbar {
            display: none;
        } `
      : `
        // padding: 94px 50px 36px 50px;
    `}
    position: relative;
  @media {
    max-width: 765px;
  }
   {
    // display: flex;
    // justify-content: center;
    .padding-20 {
      padding: 20px;
    }
    ${(props: any) =>
      props.checkOutPopUp
        ? `
        padding: 0px; `
        : `
            // padding: 52px 15px 40px 15px;
    `}
  }

  ${(props: any) =>
    props.hideScroll
      ? `
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    `
      : ""}
`;

const PopUp = ({
  height = 400,
  width = 600,
  handleClose,
  className = "",
  closeOnEsc = false,
  closeOnOutClick = true,
  unsetHeight = false,
  children,
  hideScroll = false,
  paddingTop = false,
  checkOutPopUp = false,
  wrapperClassName = "",
  setSizeHeight = false
}: any) => {
  const wrapperRef: any = useRef();

  const handleClickOutside = (event: any) => {
    console.log('event=====>', event)
    if (
      !!wrapperRef &&
      !!closeOnOutClick &&
      wrapperRef.current !== null &&
      !wrapperRef.current.contains(event.target)
    ) {

      handleClose();
    }
  };

  const handleKeyDown = (e: any) => {
    if (
      (e.keyCode === 27 || e.key === "Escape" || e.key === "Esc") &&
      !!closeOnEsc
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    document.body.classList.add("modal-open");
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <Container className={className} setHeight={unsetHeight}  sizeHeight ={setSizeHeight}>
      <SubContainer
        className="popup-content-holder"
        width={width}
        setHeight={unsetHeight}
        sizeHeight ={setSizeHeight}
      >
        <Wrapper
          ref={wrapperRef}
          hideScroll={hideScroll}
          paddingTop={!!paddingTop}
          checkOutPopUp={checkOutPopUp}
          height={height}
          setHeight={unsetHeight}
          className={wrapperClassName}
        >
          {children}
        </Wrapper>
      </SubContainer>
    </Container>
  );
};

export default PopUp;
