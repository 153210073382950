// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show_counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  align-items: center;
  height: 100%;
  line-height: 1.75rem;
}
.show_counter .colon {
  padding-bottom: 2.5rem;
}
.show_counter .countdown {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 17%;
}
.show_counter .countdown p {
  margin: 10px 20px;
}

.expired {
  color: #000000;
  text-align: center;
  padding: 40px;
}

@media screen and (max-width: 428px) {
  .show_counter .colon {
    padding-bottom: 2rem;
  }
  .show_counter .countdown p {
    margin: 0;
  }
  .expired {
    font-size: 20px;
    padding: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/register/countdown.scss","webpack://./src/styles/Common/_colours.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EAEA,oBAAA;AADF;AAIE;EACE,sBAAA;AAFJ;AAKE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;AAHJ;AAKI;EACE,iBAAA;AAHN;;AASA;EACE,cCDY;EDEZ,kBAAA;EACA,aAAA;AANF;;AASA;EAII;IACE,oBAAA;EATJ;EAcI;IACE,SAAA;EAZN;EAiBA;IACE,eAAA;IACA,aAAA;EAfF;AACF","sourcesContent":["@import '../Common/colours.scss';\n.show_counter {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: auto;\n  align-items: center;\n  height: 100%;\n\n  line-height: 1.75rem;\n\n\n  .colon {\n    padding-bottom: 2.5rem;\n  }\n\n  .countdown {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 17%;\n\n    p {\n      margin: 10px 20px;\n    }\n\n\n  }\n}\n.expired{ \n  color: $black-color;\n  text-align: center;\n  padding:40px;\n}\n\n@media screen and (max-width:428px) {\n\n  .show_counter {\n\n    .colon {\n      padding-bottom: 2rem;\n    }\n\n    .countdown {\n\n      p {\n        margin: 0;\n      }\n    }\n  }\n\n  .expired {\n    font-size: 20px;\n    padding:30px;\n  }\n\n}\n","$primary-color1: #F0AE00;\n$primary-color1-shades: (\n  shade1: #FEDB7E,\n);\n\n$primary-color2: #326CB5;\n$primary-color2-shades: (\n  shade1: #065BBE,\n  shade2: #1F57C3,\n  shade3: #2F91EC,\n  shade4: #0000FF\n);\n\n$primary-color3: #FF0000;\n\n$secondary-color1: #5E6D55;\n\n$secondary-color2: #8E8E8E;\n$secondary-color2-shades: (\n  shade1: #001E00,\n  shade2: #37474F,\n  shade3: #555,\n  shade4: #D5E0D5,\n  shade5: #CCC,\n  shade6: #E4EBE4,\n  shade7: #F5F5F5,\n  shade8: #808080\n);\n\n$white-color: #FFFFFF;\n$black-color: #000000;\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
