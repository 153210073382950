// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobListing_container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 5rem;
}

@media screen and (max-width: 428px) {
  .jobListing_container {
    display: flex;
    width: 90%;
    margin: 3rem;
    margin-left: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/jobListing/jobListing.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,YAAA;AACJ;;AAGA;EACI;IACI,aAAA;IACA,UAAA;IACA,YAAA;IACA,iBAAA;EAAN;AACF","sourcesContent":[".jobListing_container {\n    display: flex;\n    flex-direction: column;\n    width: 90%;\n    margin: 5rem;\n\n}\n\n@media screen and (max-width:428px) {\n    .jobListing_container {\n        display: flex;\n        width: 90%;\n        margin: 3rem;\n        margin-left: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
