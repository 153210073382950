import { useEffect } from "react";
import { instruction } from "../../helper";
import "../../styles/howToRegister/howToRegister.scss"
import Layout from "../HOC/Layout";

const HowToRegister = () => {
  const currentRoute = window.location.pathname
  useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
}, [])
  const information = instruction.filter((i: any) => i.route === currentRoute) || []
  return (
    <Layout>
      {
        !!information.length && information.map((i: any) => (
          <div className="howToRegister_container">
            <h1>{i.heading}</h1>
            <div className="instruction_container">
              <text className="medium-font ins_heading">{i.subHeading}</text>
              <ul>
                {
                  i.intructions.map((j: any) => (
                    <div className="info_container small-font">
                      <li>{j.content}</li>
                      {
                        j.link && <a href="mailto:anjali.garg@repozitory.com?body=hackUniv registration">{j.link}</a>
                      }
                    </div>
                  ))
                }
              </ul>
              <div className="instruction_bottom bold small-font">
                {i.formLink.content}
                <a target="_blank" href={i.formLink.link}>{i.formLink.link}</a>
                {
                  !!i.formLink.additionalInfo && i.formLink.additionalInfo
                }
              </div>
            </div>
          </div>
        ))
      }
    </Layout>
  );
};

export default HowToRegister;
