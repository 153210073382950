import '../../styles/about/about.scss';

const AboutCard = ({ data }: any) => {
    return (
        <div className="about-card">
            <h4 className="about-card-title medium-font">{data.title}</h4>
            <p className="about-card-desc extra_small-font">{data.desc}</p>
        </div>
    );
};

export default AboutCard;
