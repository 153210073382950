import { Timer } from "../countdown/Timer";
import info from "./constant";
import Card from "./Card";
import "../../styles/register/register.scss";
import "../../styles/register/countdown.scss";

const Register = () => (
  <div className="register_container">
    <div className="register_layout">
      <div className="register_head extra_large-font bold">Register by 31st October, 2023</div>
      <div className="timer">
        <Timer />
      </div>
    </div>
    <p className="how_work_head extra_large-font bold">How it works</p>
    <div className="register_subhead">
      {info.map((data: any) => {
        return <Card data={data} />;
      })}
    </div>
  </div>
);

export default Register;
