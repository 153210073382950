interface IncomingProps {
  label: string;
  name?: string;
  row: number;
  cols: number;
  type: string;
  placeholder: string;
  inputClassContainer: string;
  labelContainer: string;
  onChange?: (event: any) => void;
  value: any;
}

function Textarea(props: IncomingProps) {
  return (
    <div className={`${props.inputClassContainer}`}>
      <div className={`${props.labelContainer}`}>{props.label}</div>
      <textarea
        value={props.value}
        name={props.name}
        rows={props.row}
        cols={props.cols}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
}
export default Textarea;
