import gju from "../../assets/gju.svg";
import mdu from "../../assets/mdu.svg";
import tit from "../../assets/tit.svg";
import om from "../../assets/om.svg";
import jmit from "../../assets/jmit.svg";
import cdlu from "../../assets/cdlu.svg";
import uniLogo from "../../assets/uniLogo.png"

const univImage = (name: string) => {
  name = name.toLowerCase()
  switch (name) {
    case "gju":
      return gju;
    case "mdu":
      return mdu;
    case "tits":
      return tit;
    case "om":
      return om;
    case "jmit":
      return jmit;
    case "cdlu":
      return cdlu;
    default:
      return uniLogo;
  }
}
export default univImage