import { useState } from "react";

import  {contactAPI} from "../../api/api";
import "../../styles/contact/contact.scss";
import Input from "../common/Input";
import PopUp from "../common/popup/index";
import Textarea from "../common/Textarea";
import Layout from "../HOC/Layout";
import whatsappIcon from "../../assets/whatsapp_icon.svg"

const userDetails = {
  name: "",
  college: "",
  phone: "",
  email: "",
  query: "",
};
const ContactUs = () => {
  const [contactInfo, setContactInfo] = useState(userDetails);
  const [popupState, SetPopUpState] = useState(false);
  const handleChange = (event: any) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event: any) => {

    event.preventDefault();
    const resp: any = await contactAPI(contactInfo);
    if (resp.success) {
      SetPopUpState(true);
      setTimeout(() => {
        SetPopUpState(false);
        setContactInfo({ ...userDetails });
      }, 3000);
      return;
    }
    setContactInfo({ ...userDetails });
    SetPopUpState(false);
  };
  return (
    <Layout>
      <div className="contact_container">
        {popupState && (
          <PopUp
            width={640}
            height={650}
            paddingTop={true}
            handleClose={() => SetPopUpState(false)}
            unsetHeight={true}
          >
            <div className="popup_content">
              Thank you for contacting us, we will contact to you within 2
              working days.
            </div>
          </PopUp>
        )}
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit} className="form_container small-font">
          <Input
            label="Name"
            name="name"
            type="text"
            placeholder="Enter your name here"
            labelContainer="label_container"
            inputClassContainer="input-contact-form multiple"
            onChange={handleChange}
            value={contactInfo.name}
            required={true}
          />
          <Input
            label="University/College"
            name="college"
            type="text"
            placeholder="Enter your name of your university or college "
            labelContainer="label_container"
            inputClassContainer="input-contact-form"
            onChange={handleChange}
            value={contactInfo.college}
            required={true}
          />
          <Input
            label="Phone/Mobile"
            name="phone"
            type="text"
            placeholder="Enter your phone number"
            labelContainer="label_container"
            inputClassContainer="input-contact-form"
            onChange={handleChange}
            value={contactInfo.phone}
            required={true}
          />
          <Input
            label="Email"
            name="email"
            type="text"
            placeholder="Enter your email"
            labelContainer="label_container"
            inputClassContainer="input-contact-form"
            onChange={handleChange}
            value={contactInfo.email}
            required={true}
          />
          <Textarea
            type="text"
            label="Query"
            name="query"
            row={5}
            cols={50}
            placeholder="Describe your query in brief"
            inputClassContainer="input-contact-form"
            labelContainer="label_container"
            value={contactInfo.query}
            onChange={handleChange}
          />
          <button type="submit" className="submit_contact_form small-font">
            Submit
          </button>
        </form>
        <div className="connect_us_container small-font">
          <div>OR</div>
          <div> Connect with us using WhatsApp</div>
          <div className="connect_us_button extra_small-font">
            <a href="https://api.whatsapp.com/send/?phone=919034269099&text&type=phone_number&app_absent=0" target="_blank"><img src={whatsappIcon} alt="#" />Message us on Watsapp</a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactUs;