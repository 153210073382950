import '../../styles/about/about.scss';
import AboutCard from './AboutCard';

const ABOUT_CARD = [
    {
        title: "Open to everyone",
        desc: "Our hackathon focuses on diversity and inclusion, people from all skills levels and backgrounds are welcome."
    },
    {
        title: "Collaboration",
        desc: "We encourage team participation, and the maximum number of students in a team should be three."
    },
    {
        title: "Industry experts",
        desc: "Our event brings together industry experts, who will be available to offer advice and help participants grow their skills in real-world settings."
    },
    {
        title: "Challenges",
        desc: "Our challenges offer something for everyone. Different levels of difficulty, same chance to win."
    }
]

const ABOUT_DESC =
{
    header: "About hackuniv",
    subheader: "Ready to make a difference? HackUniv encourages you to put your skills to the test and work on interesting and impactful projects. Come together with like-minded individuals to create something amazing."
}


const About = () => {
    return (
        <div className="about_container">
            <p className="about_main_head extra_large-font bold">{ABOUT_DESC.header}</p>
            <p className="about_description small-font light">
                {ABOUT_DESC.subheader}
            </p>
            <div className="about_subhead">
                <div className="about-card-grid">
                    {ABOUT_CARD.map((data: any) => {
                        return <AboutCard data={data} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default About;