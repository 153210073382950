// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/bg_image.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/bg_mobile.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/bg_desktop.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;400&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: "karla";
  font-weight: 400;
}

.main_container {
  width: 100%;
  position: relative;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 150% 115vh;
}
@media screen and (max-width: 428px) {
  .main_container {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
    background-size: 100% 130vh;
  }
}

.backgroundImage {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-repeat: no-repeat;
  background-size: auto 800px;
  background-position-x: right;
  height: 800px;
}
@media screen and (max-width: 428px) {
  .backgroundImage {
    background-image: none;
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/main/common.scss","webpack://./src/styles/Common/commonFont.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,oBCNG;EDOH,gBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,kBAAA;EACA,yDAAA;EACA,4BAAA;EACA,2BAAA;AAAJ;AAEI;EAPJ;IAQQ,yDAAA;IACA,4BAAA;IACA,2BAAA;EACN;AACF;;AAEA;EACI,yDAAA;EACA,4BAAA;EACA,2BAAA;EACA,4BAAA;EACA,aAAA;AACJ;AAAI;EANJ;IAOQ,sBAAA;IACA,YAAA;EAGN;AACF","sourcesContent":["@use '../Common/commonFont.scss';\n\n* {\n    box-sizing: border-box;\n}\n\nbody {\n    margin: 0px;\n    font-family: commonFont.$karla;\n    font-weight: 400;\n}\n\n.main_container {\n    width: 100%;\n    position: relative;\n    background-image: url(\"../../assets/bg_image.svg\");\n    background-repeat: no-repeat;\n    background-size: 150% 115vh;\n\n    @media screen and (max-width:428px) {\n        background-image: url('../../assets/bg_mobile.svg');\n        background-repeat: no-repeat;\n        background-size: 100% 130vh;\n    }\n}\n\n.backgroundImage {\n    background-image: url(\"../../assets/bg_desktop.png\");\n    background-repeat: no-repeat;\n    background-size: auto 800px;\n    background-position-x: right;\n    height: 800px;\n    @media screen and (max-width:428px) {\n        background-image: none;\n        height: 100%;\n    }\n}","@import 'https://fonts.googleapis.com/css2?family=Karla:wght@300;400&display=swap';\n\n$karla:'karla';"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
