import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SERVER_URL } from "../../config";
import Layout from "../HOC/Layout";
import users from "../../assets/users.png";
import imageIcon from "../../assets/imageIcon.png";
import Countdown from "react-countdown";
import "../../styles/teams/team.scss";
import  univImage  from "./teamhelpers";
import like from "../../assets/like.svg";
import PopUp from "../common/popup/index";
import Spinner from "../common/Spinner";
import Loader from "../common/Loader";
import { likeApi, otpApi, resendApi } from "../../api/api";
import Otp from "./Otp";
import bluethumb from "../../assets/thumbs up.svg";
import Success from "../common/Success";
import OtpPopup from "../common/OtpPopup";

const Data = ({ teamIcon, names = [] }: any) => {
  return (
    <>
      <img className="team_logo" src={teamIcon} alt="teamLogo" />
    </>
  );
};

const Card = ({ icon_subheading = [] }: any) => {
  return icon_subheading?.map((j: any) => {
const ImageComponent = ({ bufferData }:any) => {
  // Convert buffer data to a Blob object and create an object URL
  const blob = new Blob([new Uint8Array(bufferData.data)], {
    type: "image/jpeg",
  });
  const objectUrl = URL.createObjectURL(blob);

  return (
    <img src={objectUrl} alt="Image" />
  );
}

    return (
      <div key={j._id} className="team_members_layout">
        {/* <img src={j?.image || users} alt="users" /> */}
      {j.image && <ImageComponent bufferData={j.image} />}
        <div className="content_layout">
          <h3>{j.full_name}</h3>
          <h3>{j.qualification}</h3>
        </div>
      </div>
    );
  });
};

const List = ({ description }: any) => {
  return <p>{description}</p>;
};

const ListItem = ({ listContent }: any) => {
  return listContent.map((k: any, j: any) => {
    return (
      <div key={j}>
        <ul>
          <li>{k}</li>
        </ul>
      </div>
    );
  });
};

const IconImage = ({ icon = [] }: any) => {
  return icon?.map((m: any) => {
    return (
      <div key={m.id} className="image">
        <img src={m || imageIcon} alt="images" />
      </div>
    );
  });
};

const IconVideo = ({ videoIcon, names }: any) => {
  return (
    <div className="video video_icon">
      {videoIcon.map((i: any) => {
        return <img src={i} alt="video" />;
      })}
    </div>
  );
};

const Team = () => {
  const [teamData, setTeamData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [enableResend, setEnableResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(new Date().getTime() + 60000);
  const [error, setError] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLiked, setIsliked] = useState(false);
  const [totalLike, setTotalLike] = useState(0);
  const [isContent, setIsContent] = useState(false);
  const [email, setEmail] = useState("");
  const params = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    fetchTeamDetails();
    return () => {
      setIsliked(false);
    };
  }, []);

  const fetchTeamDetails = () => {
    setIsLoading(true);
    fetch(`${SERVER_URL}/teams/details/${params.id}`, {


      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTeamData(res.data);
        setTotalLike(res.data?.likes || 0);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleOnPopup = () => {
    setOpenPopup(!openPopup);
    setIsTrue(false);
    setError("");
    setOtp("");
    setEmail("");
    setEnableResend(false);
  };

  const handleChange = (event: any) => {
    const email = event.target.value;
    setError("");
    setEmail(email);
  };

  const handleOnSubmit = async (event: any) => {
    const id = params.id;
      setIsSubmit(true);
    event.preventDefault();
    if (isTrue) {
      const resp: any = await otpApi(otp, email, id);
      if (!resp?.error) {
        setIsTrue(false);
        setIsSubmit(false);
        setIsLoading(false);
        setOpenPopup(false);
        setEmail("");
        setOtp("");
        setEnableResend(false);
        setIsliked(true);
        setTotalLike(totalLike + 1);
        setIsContent(true);
        setTimeout(() => setIsContent(false), 3000);
      } else {
        setError(resp.error);
        setIsLoading(false);
        setIsSubmit(false);
      }
    } else {
      setIsSubmit(true);
      const resp: any = await likeApi(email, id);
      if (!resp?.error) {
        setIsTrue(true);
        setIsSubmit(false);
        setIsLoading(false);
        setError("");
        setResendTimer(new Date().getTime() + 60000);
      } else {
        setError(resp.error);
        setIsLoading(false);
        setIsSubmit(false);
      }
    }
  };

  const resendHandler = async (event: any) => {
    setIsLoading(true);
    setError("");
    event.preventDefault();
    if (!enableResend) {
      setIsLoading(false);
      return;
    }
    const id = params.id;
    const resp: any = await resendApi(email, id);
    if (!resp?.error) {
      setIsLoading(false);
      setResendTimer(new Date().getTime() + 60000);
      setEnableResend(false);
    } else {
      setIsLoading(false);
      setError(resp.error);
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    setIsLoading(true);
    if (completed) {
      // Render a completed state
      setIsLoading(false);
      setEnableResend(true);
      return;
    } else {
      setIsLoading(false);
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="teamDetail_container">
            {/* <Data teamIcon={teamData?.image || teamlogo} names={teamData?.team_name} /> */}
            {/* {teamData?.image && (
              <img className="team_logo" src={teamData?.image} alt="teamLogo" />
            )} */}
           {/* <Data names={teamData?.team_name} /> */}
            <div className="like_container">
              <h1 className="ultra_large-font">{teamData?.team_name}</h1>
              <div className="blueLike_layout">
                <img src={bluethumb} alt="like.png" className="like_image" />
                <h3> {totalLike} </h3>
              </div>
              <div className="like_layout">
                <div className="medium-font">Like this team</div>                
                {!isLiked ? (
                  <img
                    src={like}
                    alt="like.png"
                    className="like_image"
                    onClick={handleOnPopup}
                  />
                ) : (
                  <img src={bluethumb} alt="like.png" className="like_image" />
                )}
                {openPopup && (
                      <OtpPopup
                          isTrue={isTrue}
                          error={error}
                          setOtp={(value: any) => setOtp(value)}
                          handleOnPopup={handleOnPopup}
                          isSubmit={isSubmit}
                          otp={otp}
                          resendHandler={resendHandler}
                          enableResend={enableResend}
                          resendTimer={resendTimer}
                          handleOnSubmit={handleOnSubmit}
                          setOpenPopup={() => setOpenPopup(false)}
                          email={email}
                          setIsLoading={setIsLoading}
                          setEnableResend={setEnableResend}
                        >
                          {!isTrue && (
                            <>
                              <p className="email_heading">
                                Kindly enter your valid email address{" "}
                              </p>
                              <input
                                type="text"
                                name="email"
                                value={email}
                                placeholder="Enter your email"
                                onChange={handleChange}
                                pattern="^[a-zA-Z0-9]+@gmail\.com$"
                                required
                              />
                            </>
                          )}
                      </OtpPopup>
                )}
              </div>
              {isContent && <Success message="Thank you! Your response has been submitted." />}
            </div>
            {teamData.university?.college_logo && (
              <Data teamIcon={univImage(teamData.university?.college_name)} />
            )}
          </div>
          <div className="team_container">
            <h1>Team Members</h1>
            <div className="card_layout">
              <Card icon_subheading={teamData.members} />
            </div>
            {teamData?.project_description && (
              <>
                <h1 className="project_heading">Project Description</h1>
                <div className="list_layout project_description medium-font">
                  <List description={teamData.project_description} />
                </div>
              </>
            )}
            {teamData?.stack_used && !!teamData.project_feature?.length && (
              <>
                <h1>Project Features</h1>
                <div className="lists_layout">
                  <ListItem listContent={teamData.project_feature} />
                </div>
              </>
            )}
            {teamData?.stack_used && !!teamData.stack_used.length && (
              <>
                <h1>Stack Used</h1>
                <div className="lists_layout">
                  <ListItem listContent={teamData.stack_used} />
                </div>
              </>
            )}
            {/* {((teamData.image && !!teamData.image.length) ||
          (teamData.video && !!teamData.video.length)) && <h1>Any Image/Video</h1>}
        {teamData?.image && !!teamData.image.length && (
          <div className="iconImage_layout">
            <IconImage icon={[]} />
          </div>
        )} */}
            {/* {teamData.video && !!teamData.video.length && (
          <div className="iconVideo_layout">
            <IconVideo videoIcon={teamData.video} />
          </div>
        )} */}
          </div>
        </>
      )}
    </Layout>
  );
};
export default Team;
