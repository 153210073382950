interface IncomingProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  inputClassContainer: string;
  labelContainer: string;
  onChange: (event:any) => void;
  value: any;
  required: boolean;
  pattern?: string;
}

function Input(props: IncomingProps) {
  return (
    <div className={`${props.inputClassContainer}`}>
      <div className={`${props.labelContainer}`}>{props.label}</div>
      <input
        id={props.name}
        value={props.value}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        required={props.required}
        pattern={props.pattern}
      />
    </div>
  );
}

export default Input;
