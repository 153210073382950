import React, { FormEvent, MouseEvent, MouseEventHandler, useState } from 'react'
import OtpPopup from '../common/OtpPopup'
import "../../styles/Project/ApplyOtp.scss";
import { resendUserApplicant, verifyUserApplicant } from '../../api/api';

interface IApplyOtp {
    handleOnPopup: MouseEventHandler<HTMLButtonElement>,
    setOpenPopup: Function,
    email: string,
    jobId: string,
    setIsContent: Function
}

function ApplyOtp({ handleOnPopup, setOpenPopup, email, jobId, setIsContent}: IApplyOtp) {
    const [error, setError] = useState<string>('')
    const [otp, setOtp] = useState<string>('')
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [enableResend, setEnableResend] = useState<boolean>(false);
    const [resendTimer, setResendTimer] = useState<number>(new Date().getTime() + 60000);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
     setIsSubmit(true)
     try {
        e.preventDefault();
        const resp: any = await verifyUserApplicant({job_id: jobId, email: email, otp: otp });
        if (resp.success) {
            setIsContent(true)
            setOpenPopup('success')
            setIsSubmit(false)
            return;
        }
        setIsSubmit(false)
     } catch(err: any) {
        setIsSubmit(false)
        setError(err.error)
     }
    }

    const resendHandler =async (e: MouseEvent<HTMLButtonElement>) => {
        setIsSubmit(true)
        e.preventDefault();
       const resp =  await resendUserApplicant({job_id: jobId, email: email });
       if (resp.success) {
        setError('')
        setEnableResend(false)
        setResendTimer(new Date().getTime() + 60000)
      }
      setIsSubmit(false)
    }

    return (
        <div className='popup_container'>
            <div>
                <OtpPopup
                    isTrue={true}
                    error={error}
                    setOtp={(value: any) => setOtp(value)}
                    handleOnPopup={handleOnPopup}
                    isSubmit={isSubmit}
                    otp={otp}
                    resendHandler={resendHandler}
                    enableResend={enableResend}
                    resendTimer={resendTimer}
                    handleOnSubmit={handleOnSubmit}
                    setOpenPopup={() => setOpenPopup(false)}
                    email={email}
                    setIsLoading={setIsLoading}
                    setEnableResend={setEnableResend}
                >
                    <div></div>
                </OtpPopup>
            </div>
        </div>
    )
}

export default ApplyOtp