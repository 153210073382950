import PopUp from "./popup/index"
import tick from "../../assets/tick.svg";
import "./popup/index.scss";

interface ISuccess {
    message: string;
}

function Success({ message }: ISuccess) {
  return (
    <PopUp height={500} width={400} unsetHeight={true}>
    <div className="success_container">
      <img src={tick} />
      <p>{message}</p>
    </div>
  </PopUp>
  )
}

export default Success