
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { SERVER_URL } from "../../config";
import "../../styles/teams/participantTeam.scss";
import Spinner from '../common/Spinner'



 // const TeamData = ({ image, teamName, id }: any) => {
 //   const handleClick = async (event: any) => {
 //     const resp: any = await teamApi(teamName, id);

 //   };
 //   return (
 //     <div className="teamPage_layout">
 //       <div onClick={() => handleClick(id)}>
 //         <Link to={`/teams/${id}`}>
 //           <img src={image} alt="teamProfile" />
 //           <h2>{teamName}</h2>
 //         </Link>
 //       </div>
 //     </div>
 //   );
 // };


const ParticipantTeams = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [participantTeams, setParticipantTeams] = useState([]);
  const [year, setYear] = useState('2024'); 
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabRef: any = useRef([]);

  const tabs = ['2024','2023', '2022'];

  const fetchAllTeams = async () => {
    setIsLoading(true);
    const response = await fetch(`${SERVER_URL}/teams/${year}`);
    const json = await response.json();
    setParticipantTeams(json?.data);
    setIsLoading(false);
  }

  useEffect(() => {
    const currentTab: any = tabRef.current.find((tab: any) => tab.getAttribute('data-year') === year);
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    fetchAllTeams();
  }, [year]);
  return (
    <>
        <div className='participantTeam_Container'>
          <h1> Participating Teams</h1>
          <span className='participantTeam_heading large-font'>
            (till 2nd round)
          </span>
          <ul className="year_tab_container medium-font">
            {tabs.map((tab, index) => (
              <li
                key={index}
                data-year={tab} 
                className={year === tab ? 'active' : ''}
                onClick={() => setYear(tab)} 
                ref={(el) => (tabRef.current[index] = el)}
              >
                {tab}
              </li>
            ))}
            <span className="year_tab_bar" style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }} />
          </ul>
          {isLoading ? (
            <Spinner />
            ) : (
            <table className='table_main_container'>
              <tr className='table_heading medium-font'>
                <th className='table_heading_first'>Sr. No.</th>
                <th>Team Name</th>
                <th>Project Idea</th>
              </tr>
              {participantTeams?.map((i: any, index: number) => (
                <tr
                  key={index}
                  className={`table_row small-font ${
                    index % 2 === 0 ? 'getting_white' : ''
                  }`}
                >
                  <td>{index + 1}</td>
                  <td
                    className='table_name_row'
                    onClick={() => navigate(`/teams/${i._id}`)}
                  >
                    {' '}
                    {i.team_name}
                  </td>
                  <td>{i.project_name}</td>
                </tr>
              ))}
            </table>
          )}
        </div>
    </>
  )
};

export default ParticipantTeams;
