// import Card from "./winnerCard";
import "../../styles/participants/winner.scss";
import team_22_main from '../../assets/Winner/partnicipent.jpg'
import team_22_1 from '../../assets/Winner/team_22_1.png'
import team_22_2 from '../../assets/Winner/team_22_2.jpg'
import team_22_3 from '../../assets/Winner/team_22_3.jpg'
import team_22_4 from '../../assets/Winner/team_22_4.jpg'
import team_22_5 from '../../assets/Winner/team_22_5.jpg'
import team_23_main from '../../assets/Winner/carousel_main_page_02.jpg'
import team_23_1 from '../../assets/Winner/team_23_1.jpg'
import team_23_2 from '../../assets/Winner/team_23_2.jpg'
import team_23_3 from '../../assets/Winner/team_23_3.jpg'
import team_23_4 from '../../assets/Winner/team_23_4.jpg'
import ImageSlider from "./Carousel";
import winner_2024_img1 from '../../assets/Winner/winner_2024_img1.jpg'
import winner_2024_img2 from '../../assets/Winner/winner_2024_img2.jpg'
import winner_2024_img3 from '../../assets/Winner/winner_2024_img3.jpg'
import winner_2024_img4 from '../../assets/Winner/winner_2024_img4.jpg'
import winner_2024_img5 from '../../assets/Winner/winner_2024_img5.jpg'

const winnerteam22 = [{
  "id": "1",
  "image": team_22_main
},
{
  "id": "2",
  // "teamName": "The Neophytes",
  "image": team_22_1
},
{
  "id": "3",
  // "teamName": "HarBus",
  "image": team_22_2
},
{
  "id": "4",
  // "teamName": "Ashima",
  "image": team_22_3
},
{
  "id": "5",
  "image": team_22_4
},
{
  "id": "6",
  "image": team_22_5
}]

const winnerteam23 = [{
  "id": "1",
  "image": team_23_main
},
{
  "id": "2",
  // "teamName": "The Neophytes",
  "image": team_23_1
},
{
  "id": "3",
  // "teamName": "HarBus",
  "image": team_23_2
},
{
  "id": "4",
  // "teamName": "Ashima",
  "image": team_23_3
},
{
  "id": "5",
  "image": team_23_4
}]

const winnerteam24 = [{
  "id": "1",
  "image": winner_2024_img1
},
{
  "id": "2",
  // "teamName": "The Neophytes",
  "image": winner_2024_img2
},
{
  "id": "3",
  // "teamName": "HarBus",
  "image": winner_2024_img3
},
{
  "id": "4",
  // "teamName": "Ashima",
  "image": winner_2024_img4
},
{
  "id": "5",
  "image": winner_2024_img5
},
]

const WinnerTeam = () => (
  <div className="winner_container">
    <p className="winner_main_head extra_large-font bold"> Glimpses from HackUniv 2024, 2023 and 2022</p>
    <p className="winner_description small-font light">Witness the buzz and excitement of our past hackathons. Check out the amazing projects and winners from the previous years to get an idea of what you can expect at HackUniv.</p>
    <div className="winner_slider_container">

      <div className="winner_slider_1">
        <ImageSlider images={winnerteam24.filter(i => i.image !== winnerteam24[0].image)} imageData={winnerteam24[0].image} />
      </div>
      <div className="winner_slider_2">
        <ImageSlider images={winnerteam23.filter(i => i.image !== winnerteam23[0].image)} imageData={winnerteam23[0].image} />
      </div>
      <div className="winner_slider_3">
        <ImageSlider images={winnerteam22.filter(i => i.image !== winnerteam22[0].image)} imageData={winnerteam22[0].image} />
      </div>
    </div>
  </div>
);

export default WinnerTeam;
