
const constants: any = {
  local: {
    BASE_URL: "http://localhost:8000/api",
  },
  development: {
    BASE_URL: "http://localhost:8000/api",
  },
  staging: {
    BASE_URL: "https://www.hackuniv.com/api",
  },
  production: {
    BASE_URL: "http://localhost:8000/api",
  },
};


const NODE_ENV = process.env.REACT_APP_ENVIRONMENT || "staging" ;

export default constants;

export const BASE_URL = constants[NODE_ENV].BASE_URL;
export const SERVER_URL = `${BASE_URL}`;
