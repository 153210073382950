import OtpInput from 'react-otp-input'
import "../../styles/teams/otp.scss";

const Otp = ({ setOtp, otp, numberOfInput = 4, placeholder = '0000' }:any) => {

  const renderInput = (inputProps: any, index: number) => {
    return (
      <input
        {...inputProps}
        autoFocus={index === 0 ? true : undefined}
        maxLength={1}
        placeholder={placeholder}
        key={index}
      />
    );
  };

  return (
    <div className="otp_container">
    <div className="text-center">
      <div id="otp" className="flex">
        <OtpInput
          value={otp}
          onChange={(e:any) => setOtp(e)}
          numInputs={numberOfInput}
          inputType={"number"}
          placeholder={placeholder}
          renderInput={renderInput}
        />
      </div>
    </div>
  </div>
  )
}
export default Otp;